export default class ContatPopin {
    constructor() {
        this.element = document.querySelector('#contact');

        this.open = this.open.bind(this);
        this.close = this.close.bind(this);

        this.setup();
    }

    setup() {
        this.openers.forEach((cta) => {
            cta.addEventListener('click', this.open);
        });

        this.closers.forEach((cta) => {
            cta.addEventListener('click', this.close);
        });
    }

    cleanup() {
        this.openers.forEach((cta) => {
            cta.removeEventListener('click');
        });

        this.closers.forEach((cta) => {
            cta.removeEventListener('click');
        });
    }

    get openers() {
        return document.querySelectorAll('[data-href="#contact"]');
    }

    get closers() {
        return document.querySelectorAll('[data-close="contact"]');
    }

    open() {
        this.element.showModal();
    }

    close() {
        this.element
            .animate([{ opacity: 0 }], { duration: 200 })
            .finished.then(animation => {
                animation.commitStyles();
                this.element.close();
            });
    }
}
