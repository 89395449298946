// Libraries
import Swiper from 'swiper';
import { Navigation, Pagination, EffectCoverflow } from 'swiper/modules';

// Components
import ViewportObserver from './modules/viewportObserver.js';
new ViewportObserver();

import AnchorManager from './modules/anchorManager.js';
new AnchorManager();

import Header from './modules/header.js';
new Header();

import ContactPopin from './modules/contactPopin.js';
new ContactPopin();

import Expertises from './modules/expertises.js';
new Expertises(Swiper, [ Navigation, Pagination, EffectCoverflow ]);

import Specialties from './modules/specialties.js';
new Specialties(Swiper, [ Navigation, Pagination ]);

/** Custom element initialization example :
 * import CustomElement from './modules/customElement.js';
 * customElements.define('custom-element', CustomElement);
 **/
