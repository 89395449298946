import { DotLottieWorker } from '@lottiefiles/dotlottie-web';

export default class header {
    constructor() {
        this.element = document.querySelector('#header');

        if(!this.element)
            return;

        this.openers = document.querySelectorAll('[data-open="menu"]');
        this.closers = document.querySelectorAll('[data-close="menu"]');
        this.scroller = document.querySelector('[data-scroller="menu"]');
        this.dialog = this.element.querySelector('dialog');
        this.menuEntries = this.dialog.querySelectorAll('li:not(.home)');
        this.lottie = {
            open: {
                canvas: this.element.querySelector('#burger-anim-open'),
                instance: null,
            },
            close: {
                canvas: this.element.querySelector('#burger-anim-close'),
                instance: null,
            },
        };

        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
        this.updateMenu = this.updateMenu.bind(this);
        this.toggleSticky = this.toggleSticky.bind(this);

        this.state = {
            open: false,
        };

        this.setup();
    }

    setup() {
        this.openers.forEach((cta) => {
            cta.addEventListener('click', this.open);
        });

        this.closers.forEach((cta) => {
            cta.addEventListener('click', this.close);
        });

        this.menuEntries.forEach((entry) => {
            const link = entry.firstElementChild;
            link.addEventListener('mouseover', this.updateMenu);
            link.addEventListener('focus', this.updateMenu);
        });

        this.stickyObserver = new IntersectionObserver(
            this.toggleSticky, {
                threshold: 1,
            }
        );
        this.stickyObserver.observe(this.scroller);

        // const lottieUrl = '/wp-content/themes/opallio/img/lottie/burger.json';
        const lottieUrl = 'https://lottie.host/0cbdb3ef-2fa5-4d1d-9e4e-f66c879e010d/D0bRr9d93F.lottie';

        for(const type in this.lottie) {
            this.lottie[type].instance = new DotLottieWorker({
                canvas: this.lottie[type].canvas,
                src: lottieUrl,
                autoplay: true,
                loop: true,
            });
            this.lottie[type].instance.play().then(() => {
                console.log(`Animation ${type} started`);
                // this.lottie[type].instance.pause(); nok ?
            });
        }
    }

    cleanup() {
        this.openers.forEach((cta) => {
            cta.removeEventListener('click');
        });

        this.closers.forEach((cta) => {
            cta.removeEventListener('click');
        });

        this.menuEntries.forEach((entry) => {
            const link = entry.firstElementChild;
            link.removeEventListener('mouseover');
            link.removeEventListener('focus');
        });
    }

    open() {
        this.state.open = true;
        this.dialog.showModal();
        this.updateMenu();
    }

    close() {
        this.dialog
            .animate([{ opacity: 0 }], { duration: 200 })
            .finished.then(animation => {
                animation.commitStyles();
                this.state.open = false;
                this.dialog.close();
                this.updateMenu();
            });
    }

    updateMenu(event) {
        this.menuEntries.forEach((entry) => {
            entry.classList.remove('active');
        });

        if(this.state.open) {
            const currentEntry = (event) ? event.currentTarget : this.menuEntries[0].firstElementChild;
            currentEntry.parentElement.classList.add('active');
        }
    }

    toggleSticky(entries, observer) {
        entries.forEach((entry) => {
            this.element.dataset.sticky = !entry.isIntersecting;
        });
    }
}
