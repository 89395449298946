export default class Expertises {
    constructor(Swiper, SwiperModules) {
        this.element = document.querySelector('#expertises');

        if(!this.element)
            return;

        this.names = document.querySelectorAll('[data-expertises-slide]');
        this.Swiper = Swiper;
        this.modules = SwiperModules;
        this.slider = false;

        this.updateNames = this.updateNames.bind(this);
        this.handleNameSelected = this.handleNameSelected.bind(this);

        this.setup();
    }

    setup() {
        this.setupControls();
        this.slider = this.setupSwiper();
        this.slider.on('slideChange', this.updateNames);
        // this.names.forEach((name) => name.addEventListener('click', this.handleNameSelected));
    }

    get swiperCfg() {
        const dot = '<svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" fill="none"><path fill="var(--attenuated, black)" d="M4 .172c3.6-.874 4 1.734 4 3.871 0 2.138-.84 3.315-4 3.872-3.16.558-4-1.737-4-3.872 0-2.134.4-3 4-3.871Z"/></svg>';
        return {
            modules: this.modules,
	        centeredSlides: true,
            initialSlide: 0,
            slidesPerView: 1,
            loop: true,
            loopAdditionalSlides: 2,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                renderBullet: (index, className) => {
                    return `<span class="${className}">${dot}</span>`;
                },
            },
            effect: 'coverflow',
            coverflowEffect: {
                depth: 500,
                modifier: 0.4,
                rotate: 60,
                scale: 1,
                slideShadows: true,
                stretch: '181%',
            },
            breakpoints: {
                480: {
                    coverflowEffect: {
                        rotate: 100,
                        stretch: '120%',
                    }
                }
            },
        }
    }

    setupControls() {
        if(this.element.querySelector('.swiper-controls'))
            return;

        const wrapper = this.element.querySelector('.swiper-wrapper');
        const arrowImg = `<img src="${themeUrl}/img/icons/arrow-horizontal.svg" alt="">`;
        wrapper.parentElement.parentElement.innerHTML += `
            <div class="swiper-controls">
                <div class="swiper-pagination"></div>
                <div class="swiper-navigation">
                    <button class="swiper-button-prev op-u-nudebtn">${arrowImg}</button>
                    <button class="swiper-button-next op-u-nudebtn">${arrowImg}</button>
                </div>
            </div>`;
    }

    setupSwiper() {
        return new this.Swiper(
            this.element.querySelector('.swiper-container'),
            this.swiperCfg
        );
    }

    updateNames() {
        const newSlide = this.slider.slides[this.slider.activeIndex];
        const activeName = document.querySelector(`[data-expertises-slide="${newSlide.id}"]`);

        this.names.forEach((name) => name.dataset.active = 'false');
        activeName.dataset.active = 'true';
    }

    handleNameSelected(event) {
        const selected = event.currentTarget;
        const targetSlide = document.getElementById(selected.dataset.expertisesSlide);

        this.slider.slideToLoop(
            targetSlide.dataset.swiperSlideIndex,
            1000,
            true
        );
    }
}
