export default class AnchorManager {
    constructor() {
        this.observer = null;
        // this.defaultTitle = document.title;
        this.handleChange = this.handleChange.bind(this);

        this.setup();
    }

    setup() {
        this.observer = new IntersectionObserver(this.handleChange, this.options);

        for(const id  in this.relations) {
            if(this.relations[id].target) {
                this.observer.observe(this.relations[id].target);
            }
        }
    }

    cleanup() {
        this.observer.disconnect();
    }

    get options() {
        return {
            root: document,
            rootMargin: "0px",
            threshold: 0.5,
        }
    }

    get cleanUri() {
        const uri = window.location.toString();
        return uri.substring(0, uri.indexOf("#"));
    }

    get relations() {
        const relations = {};
        const anchors = document.querySelectorAll('[href*="#"], [data-href*="#"]');

        anchors.forEach((anchor) => {
            let id = (anchor.hasAttribute('href') ? anchor.href : anchor.dataset.href).split('#');
            id = id[id.length - 1];

            if(id) {
                relations[id] = {
                    anchor: anchor,
                    title: anchor.textContent.trim(),
                    target: document.querySelector('#'+id),
                }
            }
        });

        return relations;
    }

    handleChange(entries, observer) {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                const id = entry.target.id;
                // const relation = this.relations[id];
                // document.title = relation.title;
                window.history.pushState('', '', '#'+id);
            } else {
                // document.title = this.defaultTitle;
                window.history.pushState('', '', this.cleanUri);
            }
        });
    }
}
