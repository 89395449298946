export default class ViewportObserver {
    constructor() {
        this.observer = null;
        this.handleChange = this.handleChange.bind(this);
        this.observed = document.querySelectorAll('[data-viewport]');

        this.setup();
    }

    setup() {
        this.observer = new IntersectionObserver(this.handleChange, this.options);

        this.observed.forEach((target) => {
            this.observer.observe(target);
        });
    }

    cleanup() {
        this.observer.disconnect();
    }

    get options() {
        return {
            root: document,
            rootMargin: "0px",
            threshold: 0.4,
        }
    }

    handleChange(entries, observer) {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                entry.target.dataset.viewport = 'true';
            }
        });
    }
}
