export default class Specialties {
    constructor(Swiper, SwiperModules) {
        this.element = document.querySelector('.op-c-ExpertiseSpecialties');
        this.Swiper = Swiper;
        this.modules = SwiperModules;
        this.slider = false;

        if(this.element) {
            this.setup();
        }
    }

    setup() {
        this.slider = this.setupSwiper();
    }

    cleanup() {
        this.slider.destroy();
    }

    get swiperCfg() {
        const dot = '<svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" fill="none"><path fill="var(--attenuated, black)" d="M4 .172c3.6-.874 4 1.734 4 3.871 0 2.138-.84 3.315-4 3.872-3.16.558-4-1.737-4-3.872 0-2.134.4-3 4-3.871Z"/></svg>';
        return {
            modules: this.modules,
            loop: false,
            slidesPerView: 1.2,
            spaceBetween: 30,
            centeredSlides: false,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                renderBullet: (index, className) => {
                    return `<span class="${className}">${dot}</span>`;
                },
            },
            breakpoints: {
                380: {
                    slidesPerView: 1.6,
                },
                520: {
                    slidesPerView: 2.2,
                    centeredSlides: true,
                },
                780: {
                    slidesPerView: 3.2,
                    centeredSlides: true,
                },
            },
        }
    }

    setupSwiper() {
        return new this.Swiper(
            this.element.querySelector('.swiper-container'),
            this.swiperCfg
        );
    }
}
